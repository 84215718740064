import React, {FC} from 'react';
import {Text, Center, Heading, Progress} from '@chakra-ui/react';
import {useQuery} from '@tanstack/react-query';
import {Puddleglum} from '../puddleglum';
import {USDollar} from '../utils/format';

interface PaymentAnalyticCardProps {
	type: 'all' | 'recent' | 'pending' | 'pending-invoices';
	scope: 'organization' | 'employer' | 'all';
}

const PaymentAnalyticCard: FC<PaymentAnalyticCardProps> = ({type, scope}) => {
	const {data: payment} = useQuery(['provider-payments', type], async () => {
		if (scope === 'all') {
			const reply =
				await Puddleglum.Controllers.Admin.AdminAnalyticsController.getPayments(type);
			return reply.data;
		}

		if (scope === 'organization') {
			const data =
				await Puddleglum.Controllers.Providers.ProviderAnalyticsController.getPayments(
					type,
				);
			return data.data;
		}

		const data =
			await Puddleglum.Controllers.Employer.EmployerAnalyticsController.getInvoicesByType(
				type,
			);
		return data.data;
	});

	if (!payment) return <Progress size='sm' isIndeterminate />;

	return (
		<>
			<Center mt={4}>
				<Heading color='teal.500' size='lg'>
					{USDollar.format(payment.total)}
				</Heading>
			</Center>
			<Center mb={4}>
				<Text color='gray.500'>{payment.count} total</Text>
			</Center>
		</>
	);
};

export default PaymentAnalyticCard;
